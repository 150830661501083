/* DataFilteration.css */

.dropdown-menu-container {
  border: 1px solid black;
  padding: 5px;
  max-height: 150px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
}

.dropdown-actions {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.dropdown-btn {
  margin-left: 10px;
}

.dropdown-columns {
  display: flex;
  flex-wrap: wrap;
}

.dropdown-columns label {
  width: 25%; /* Adjust to fit 4 columns */
  box-sizing: border-box;
  text-align: left;
}

.wrapped-data {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  line-height: 1.5;
  text-align: left;
}


.main-table th,
.main-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.main-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.main-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.main-table tr:hover {
  background-color: #ddd;
}

