.App {
  text-align: center;
}
.dropdown-container-all-before-table {
  text-align: center;
  float: left;
}

.hit-center {
  text-align: left;
}

.align-left {
  text-align: left;
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
}

.table th,
.table td {
  padding: 2px;
  border: 1px solid #ddd;
  word-wrap: break-word;
}

.table th {
  background-color: #343a40;
  color: #fff;
}

.word-limit {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.container {
  margin-left: 3%;
}

.container-nav{
  margin-left: 3%;
}

/* auth-page style */
.auth-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.auth-main-container {
  width: 300px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.auth-title {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-error {
  color: red;
  margin-bottom: 20px;
}

.auth-success {
  color: green;
  margin-bottom: 20px;
}

.auth-toggle-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-toggle-btn {
  background-color: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
}


.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
}

input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

* {
  margin: 0px;
  padding: 0px;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
}

.error {
  color: red;
  margin-top: 10px;
}

th,
td {
  border-width: 1px !important; /* Adjust the width here */
  border-style: dashed !important;
  border-color: black;
  padding: 10px !important;
}

.wrapped-data {
  word-break: keep-all; /* This will break the word at any character to wrap it */
  white-space: normal; /* This ensures the text wraps onto the next line */
  min-width: 160px;
  padding: 10px !important;
  text-align: left !important;
}

.link-property {
  word-break: break-all; /* This will break the word at any character to wrap it */
}

body {
  margin-left: 50px !important;
}

.sidebar {
  background: #a8d5e5;
  width: auto !important;
}

h1 {
  color: #165a72;
  margin: 50px auto;
  font-size: 40px;
}
.menu-headline {
  margin-bottom: 40px;
  margin-top: 20px;
}
h2 {
  color: #165a72;
}

body {
  margin: 0;
  font-family: "Figtree", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Figtree", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background: #ffffff;
  font-size: 24px;
}

/* th, td {
  border-width: 1px !important; 
  border-style: solid;
  border-color: black;
  padding: 8px; 
}


.wrapped-data {
  word-break: break-all; 
  white-space: normal;   
  min-width: 40px;
  padding: 5px !important;
} */

.color-maroon {
  color: maroon !important;
  font-weight: bolder;
}

.nav-item {
  font-weight: bold;
}

.navbar {
  position: fixed;
}

.nav-heading-name{
  color: white !important;
}

.nav-heading-name-main {
  color: maroon !important;
}

