.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

/* Input field styling */
input.form-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%; /* Make the input full width */
}

/* Textarea styling */
textarea.form-input {
  padding: 10px; /* Same padding as input */
  font-size: 16px; /* Same font size as input */
  border: 1px solid #ccc; /* Same border style */
  border-radius: 5px; /* Same border radius */
  width: 100%; /* Make the textarea full width */
  resize: vertical; /* Allow vertical resizing only */
  height: 100px; /* Set a default height; you can adjust this as needed */
  box-sizing: border-box; /* Include padding and border in element's total width/height */
}

.scroll-to-top {
  position: fixed;
  bottom: 20px; /* Adjust the position from the bottom */
  right: 20px; /* Adjust the position from the right */
  background-color: #007bff; /* Button background color */
  color: white; /* Arrow color */
  border: none;
  border-radius: 50%; /* Circular shape */
  width: 40px;
  height: 40px;
  font-size: 20px; /* Arrow size */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3); /* Optional shadow */
  z-index: 1000; /* Ensure it stays above other elements */
}

.scroll-to-top:hover {
  background-color: #0056b3; /* Darker blue on hover */
}
